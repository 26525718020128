import AOS from 'aos';


const initAOS = () => {
  const options = {
    duration: 700,
    easing: 'ease-out-quad',
    once: true,
  }

  AOS.init(options)

}

export default initAOS;
