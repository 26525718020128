import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['federation', 'association', 'club', 'noteCouncil', 'council'];
  static values = {
    clubs: Object
  };

  createElement(value) {
    const el = document.createElement('option');
    el.value = value;
    el.append(value);

    return el;
  }

  clearElement(target) {
    target.innerHTML = '';
    target.append(this.createElement(' '))
  }

  connect() {
    this.federationTarget.addEventListener('change', (event) => {
      this.clearElement(this.associationTarget)
      this.clearElement(this.clubTarget)

      const associations = Object.keys(this.clubsValue[event.target.value])
      associations.forEach((association) => {
        this.associationTarget.append(this.createElement(association))
      })
    });

    this.associationTarget.addEventListener('change', (event) => {
      this.clearElement(this.clubTarget)

      const clubs =this.clubsValue[this.federationTarget.value][event.target.value];
      clubs.forEach((club) => {
        this.clubTarget.append(this.createElement(club))
      })
    });

    this.noteCouncilTarget.addEventListener('change', (event) => {
      if (event.target.checked) {
        this.councilTarget.classList.remove('d-none');
      } else {
        this.councilTarget.classList.add('d-none');
      }
    });
  }
}
