import Flickity from 'flickity';


const initFlickity = () => {

  var customerLogoCarousel = document.querySelector('.customer-logo-carousel');
  var whyGowGates = document.querySelector('.why-gow-gates');
  var mainCarousel = document.querySelector('.main-carousel');

  if (!!customerLogoCarousel) {
    var flkty = new Flickity( customerLogoCarousel, {
      // options
      autoplay: 6000,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      contain: true
    });
  }

  if (!!whyGowGates) {
    var flkty = new Flickity( whyGowGates, {
      // options
      pageDots: true,
      prevNextButtons: false,
      cellAlign: 'left',
      prevNextButtons: false,
    });
  }


  if (!!mainCarousel) {
    var flkty = new Flickity( mainCarousel, {
      // options
      cellAlign: 'left',
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
    });
  }

}

export default initFlickity;
