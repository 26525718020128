import { Controller } from "@hotwired/stimulus"
import Quill from 'quill';

const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'align': [] }],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],
  ['blockquote', 'code-block'],
  [{ 'color': [] }, { 'background': [] }],
  ['link'],
  ['clean']
];

export default class extends Controller {
  static targets = ['text', 'richText'];

  connect() {
    this.richTextTarget.insertAdjacentHTML('afterbegin', this.textTarget.value);

    const editor = new Quill(this.richTextTarget, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions
      }
    });

    editor.on('text-change', () => {
      const editorElement = this.richTextTarget.querySelector('.ql-editor');
      this.textTarget.value = editorElement.innerHTML;
    });
  }
}
